import { render, staticRenderFns } from "./saveitem.vue?vue&type=template&id=e71e61da"
import script from "./saveitem.vue?vue&type=script&lang=js"
export * from "./saveitem.vue?vue&type=script&lang=js"
import style0 from "./saveitem.vue?vue&type=style&index=0&id=e71e61da&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports